import React from 'react';
// import HomeIcon from '@mui/icons-material/Home';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import LanguageIcon from '@mui/icons-material/Language';

export const SidebarData = [
  // {
  //   title: 'Dashboard',
  //   icon: <HomeIcon />,
  //   link: '/admin',
  // },
  {
    title: '주보',
    icon: <StickyNote2Icon />,
    link: '/admin/jubo',
  },
  {
    title: '링크',
    icon: <LanguageIcon />,
    link: '/admin/link',
  },
];
