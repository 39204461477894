import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import ExternalRedirect from "./components/User/ExternalRedirect";
import Login from "./components/Protected/Login";
import Admin from "./components/Protected/Admin";
import UploadJubo from "./components/Protected/page/UploadJubo";
import ManageLinks from "./components/Protected/page/ManageLinks";

import AuthState from "./contexts/auth/AuthState";
import links from "./config/links.json";

import "./App.css";

const RedirectCheck = () => {
  const location = useLocation();

  useEffect(() => {
    const hostname = window.location.hostname;
    console.log(hostname);
    console.log(location.pathname);

    if (hostname === "www.chicagoonnuri.com" && location.pathname === "/") {
      window.location.replace("https://chicago.onnuri.or.kr");
    }
  }, [location]);

  return null;
};

function App() {
  return (
    <AuthState>
      <Router>
        <RedirectCheck /> {/* Component to handle the redirect logic */}
        <Routes>
          {/* <Route exact path="/" element={<Home />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/jubo" element={<UploadJubo />} />
          <Route path="/admin/link" element={<ManageLinks />} />
          <Route
            path="/jubo"
            element={<ExternalRedirect to={links.weekly_jubo} />}
          />
          <Route
            path="/qt"
            element={<ExternalRedirect to={links.monthly_qt} />}
          />
          <Route
            path="/playlist"
            element={<ExternalRedirect to={links.playlist} />}
          />
          <Route
            path="/retreat"
            element={<ExternalRedirect to={links.retreat} />}
          />
          <Route
            path="/talent"
            element={<ExternalRedirect to={links.talent} />}
          />
          <Route
            path="/bookclub"
            element={<ExternalRedirect to={links.bookclub} />}
          />
          <Route
            path="/prayer"
            element={<ExternalRedirect to={links.prayer} />}
          />
          <Route
            path="/ntreading"
            element={<ExternalRedirect to={links.ntreading} />}
          />
          <Route
            path="/retreat-playlist"
            element={<ExternalRedirect to={links.retreat_playlist} />}
          />
        </Routes>
      </Router>
    </AuthState>
  );
}

export default App;
