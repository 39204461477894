import React, { useState, useEffect } from "react";
import Admin from "../Admin";
import Modal from "react-modal";
import API from "../../../config/api";

import UploadModal from "../Jubo/UploadModal";
import SetLiveModal from "../Jubo/SetLiveModal";
import DeleteModal from "../Jubo/DeleteModal";

import loadingIcon from "../../../media/icon/Loading_icon.gif";
import axios from "axios";

import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LockIcon from "@mui/icons-material/Lock";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Modal.setAppElement("#root"); // Ensure accessibility

const UploadJubo = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [liveModalOpen, setLiveModalOpen] = useState(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileValidationMessage, setFileValidationMessage] = useState("");
  const [juboData, setJuboData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [liveSuccess, setLiveSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    const fileNamePattern = /^\d{2}-\d{2}-\d{4}_Onnuri_Downtown_Chicago\.pdf$/;

    if (file.type !== "application/pdf" || !fileNamePattern.test(file.name)) {
      setFileFormatError(true);
      setFileValidationMessage("Please upload a PDF file.");
      setSelectedFile(null);
      return;
    }

    setFileValidationMessage("");
    setSelectedFile(file);

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;
      console.log(binaryStr);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedFile) {
      return;
    }
    // You would typically handle the file upload here.
    console.log(selectedFile);
    setModalIsOpen(false);
    setSelectedFile(null);
    setFileValidationMessage("");
  };

  const handleSetLive = (e) => {
    setLiveModalOpen(true);
  };

  const handleDelete = (e) => {
    setDeleteModalOpen(true);
  };

  const fetchJuboData = async () => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.get(API.LIST_PDF, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJuboData(response.data);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJuboData();
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      toast.success("주보가 업로드 되었습니다.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      // Reset uploadSuccess to false
      setUploadSuccess(false);
    }

    if (liveSuccess) {
      toast.info("주보가 활성화 되었습니다.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setLiveSuccess(false);
    }

    if (deleteSuccess) {
      toast.error("주보가 삭제되었습니다.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setDeleteSuccess(false);
    }

    if (fileFormatError) {
      toast.error("파일 형식이 잘못되었습니다.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setFileFormatError(false);
    }
  }, [uploadSuccess, liveSuccess, deleteSuccess, fileFormatError]);

  const sortedJuboData = [...juboData].sort(
    (a, b) =>
      Date.parse(b.name.substring(0, 10).replace(/-/g, "/")) -
      Date.parse(a.name.substring(0, 10).replace(/-/g, "/"))
  );

  return (
    <Admin>
      <ToastContainer />
      <div className="uploadPage">
        <div>
          <h1 className="mb-3">
            <strong>주보</strong>
          </h1>
          <button
            type="button"
            className="btn btn-primary currentJubo"
            onClick={() => setModalIsOpen(true)}
          >
            Upload PDF
          </button>
          {/* <button type='button' className='btn btn-secondary uploadBtn'>
            <a href={links.wcoeekly_jubo} target='_blank' rel='noreferrer'>
              현재 주보 링크
            </a>
          </button> */}
          <UploadModal
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            handleFileChange={handleFileChange}
            handleSubmit={handleSubmit}
            selectedFile={selectedFile}
            fileValidationMessage={fileValidationMessage}
            setSelectedFile={setSelectedFile}
            setFileValidationMessage={setFileValidationMessage}
            setIsLoading={setIsLoading}
            fetchJuboData={fetchJuboData}
            setUploadSuccess={setUploadSuccess}
          />
          <SetLiveModal
            modalIsOpen={liveModalOpen}
            setModalIsOpen={setLiveModalOpen}
            selectedItem={selectedItem}
            fetchJuboData={fetchJuboData}
            setIsLoading={setIsLoading}
            setLiveSuccess={setLiveSuccess}
          />
          <DeleteModal
            modalIsOpen={DeleteModalOpen}
            setModalIsOpen={setDeleteModalOpen}
            selectedItem={selectedItem}
            fetchJuboData={fetchJuboData}
            setIsLoading={setIsLoading}
            setDeleteSuccess={setDeleteSuccess}
          />
          <div className="mb-4 mt-3 uploadRef">
            <div>* pdf 파일만 업로드해주세요.</div>
            <div>
              * 'MM-DD-YYYY_...' 형식을 지켜주세요. ex)
              01-01-2023_Onnuri_Downtown_Chicago.pdf
            </div>
            <div>* 업로드 완료 시 자동으로 업로드된 주보가 활성화됩니다.</div>
            <div>* 활성화된 주보는 삭제할 수 없습니다.</div>
          </div>
        </div>

        <div>
          <table className="table-bordered uploadTable">
            <tr>
              <th id="name">Name</th>
              <th id="date">Sunday Date</th>
              <th id="live">Live</th>
              <th id="delete">{"Delete  "}</th>
            </tr>
            {isLoading ? (
              <div className="loadingIcon">
                <img src={loadingIcon} alt="Loading..." />
              </div>
            ) : (
              <>
                {sortedJuboData.map((val, index) => (
                  <tr key={index} data-index={index}>
                    <td>
                      <a href={val.url} target="_blank" rel="noreferrer">
                        {val.name}
                      </a>
                    </td>
                    <td id="dateVal">
                      {val.name.substring(0, 10).replace(/-/g, "/")}
                    </td>
                    <td id="iconLive">
                      {val.live ? (
                        <CheckBoxIcon />
                      ) : (
                        <IconButton
                          onClick={() => {
                            setSelectedItem(val.name);
                            handleSetLive(val.name);
                          }}
                        >
                          <CheckBoxOutlineBlankIcon />
                        </IconButton>
                      )}
                    </td>
                    <td id="iconDelete">
                      {val.live ? (
                        <LockIcon />
                      ) : (
                        <IconButton
                          onClick={() => {
                            setSelectedItem(val.name);
                            handleDelete(val.name);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </table>
        </div>
      </div>
    </Admin>
  );
};
export default UploadJubo;
