const API_URL = 'https://onnuribackend-fbcece2b9905.herokuapp.com';

const API_ENPOINTS = {
  PDF: `${API_URL}/pdf`,
  LIST_PDF: `${API_URL}/listPDF`,
  LOGIN: `${API_URL}/login`,
  SET_LIVE: `${API_URL}/setLiveJubo`,
  JUBO: `${API_URL}/jubo`,
};

export default API_ENPOINTS;
