import React, { useState } from 'react';
import Modal from 'react-modal';
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios';
import API from '../../../config/api';

const DeleteModal = ({
  modalIsOpen,
  setModalIsOpen,
  selectedItem,
  fetchJuboData,
  setIsLoading,
  setDeleteSuccess,
}) => {
  const [inputVal, setInputVal] = useState('');

  const closeModal = () => {
    setInputVal('');
    setModalIsOpen(false);
  };

  const handleSubmit = () => {
    const token = sessionStorage.getItem('token');
    closeModal();
    setIsLoading(true);
    axios
      .delete(API.PDF, {
        headers: { Authorization: `Bearer ${token}` },
        data: { filename: selectedItem },
      })
      .then((response) => {
        console.log(response.data);
        fetchJuboData();
        setDeleteSuccess(true);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      overlayClassName='modalOverlay'
      className='modalContent deleteModalContent'
    >
      <h2 className='modalTitle fileUploadContent'>
        {' '}
        <WarningIcon />
        주보 삭제
      </h2>
      <div className='selectedFileWrapper'>{selectedItem}</div>
      <div>
        위의 주보를 <strong>삭제</strong>합니다
      </div>
      <div className='deletePrompt'>
        <div className='deleteComp'>
          Type <span className='deleteText'>DELETE</span> to confirm:
        </div>
        <input
          type='text'
          className='form-control deleteComp'
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value.toUpperCase())} // convert input to uppercase
        />
      </div>
      <div className='buttonContainer'>
        <button
          type='submit'
          className='btn btn-danger submitBtn'
          onClick={handleSubmit}
          disabled={inputVal !== 'DELETE'}
        >
          Delete
        </button>
        <button onClick={closeModal} className='btn btn-outline-dark'>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
