import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import API from '../../../config/api';

const SetLiveModal = ({
  modalIsOpen,
  setModalIsOpen,
  selectedItem,
  fetchJuboData,
  setIsLoading,
  setLiveSuccess,
}) => {
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = () => {
    const token = sessionStorage.getItem('token');
    closeModal();
    setIsLoading(true);
    axios
      .post(
        API.SET_LIVE,
        { filename: selectedItem },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        fetchJuboData();
        setLiveSuccess(true);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      overlayClassName='modalOverlay'
      className='modalContent liveModalContent'
    >
      <h2 className='modalTitle'>실시간 주보 설정</h2>
      <div className='selectedFileWrapper'>{selectedItem}</div>
      <div>
        위의 주보가 <strong>활성화</strong> 됩니다
      </div>
      <div className='buttonContainer'>
        <button
          type='submit'
          className='btn btn-primary submitBtn'
          onClick={handleSubmit}
        >
          Update
        </button>
        <button onClick={closeModal} className='btn btn-outline-dark'>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default SetLiveModal;
