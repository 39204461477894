import React, { useState } from "react";
import Admin from "../Admin";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ManageLinks = () => {
  const [links, setLinks] = useState([
    {
      key: "jubo",
      url: "https://onnuribackend-fbcece2b9905.herokuapp.com/jubo",
    },
    { key: "qt", url: "https://forms.gle/t4yLumCFzqR3n7G86" },
    {
      key: "summer",
      url: "https://docs.google.com/forms/d/e/1FAIpQLSdyEudYQab3F14gb2T3NTT7xq6HLCLr1MY1y5onPMILrHWQOQ/viewform",
    },
    {
      key: "playlist",
      url: "https://youtube.com/playlist?list=PL-tUiUh_ovgRvjQmuwzLe92_KK6-Bp_tb&si=WII5eSSnLsKZHf4T",
    },
  ]);

  // New state variables to track the current input values
  const [newKey, setNewKey] = useState("");
  const [newUrl, setNewUrl] = useState("");

  const deleteLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  const addLink = () => {
    const newLinks = [...links, { key: newKey, url: newUrl }];
    setLinks(newLinks);
    setNewKey("");
    setNewUrl("");
  };

  // More logic for updating and deleting links would be here

  return (
    <Admin>
      <div>
        <div className="uploadPage">
          <div>
            <h1 className="mb-3">
              <strong>링크</strong>
            </h1>
            <div className="mb-4 mt-3 uploadRef">
              <div>* www.chicagoonuri.com/XXXX 링크를 관리합니다.</div>
              <div>* 외부링크를 원하는링크로 편리하게 지정할수 있습니다.</div>
              <div>* https:// 을 포함한 전체 링크를 추가해주세요.</div>
            </div>

            <form className="d-flex align-items-center mb-5">
              <div className="me-3">
                <label htmlFor="keyInput" className="form-label">
                  Path:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="keyInput"
                  placeholder="Enter path"
                  value={newKey}
                  onChange={(e) => setNewKey(e.target.value)}
                />
              </div>
              <div className="mt-4 me-3">
                <ArrowForwardIcon />
              </div>

              <div className="me-3">
                <label htmlFor="urlInput" className="form-label">
                  External Link:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="urlInput"
                  placeholder="Enter external link"
                  value={newUrl}
                  onChange={(e) => setNewUrl(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary mt-4"
                onClick={addLink}
              >
                Add Redirect Path
              </button>
            </form>

            <div>
              {links.map((link, index) => (
                <div className="displayLinks" key={index}>
                  <p className="linkText">
                    {link.key} <ArrowForwardIcon /> {link.url}
                  </p>
                  <div className="linkBtn">
                    <button
                      onClick={() => {
                        /* put your edit logic here */
                      }}
                      className="linkEditBtn"
                    >
                      Edit
                    </button>
                    <button onClick={() => deleteLink(index)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </Admin>
  );
};

export default ManageLinks;
