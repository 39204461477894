import React from 'react';
import Modal from 'react-modal';
import BackupIcon from '@mui/icons-material/Backup';
import axios from 'axios';
import API from '../../../config/api';

Modal.setAppElement('#root'); // Ensure accessibility

const UploadModal = ({
  modalIsOpen,
  setModalIsOpen,
  handleFileChange,
  selectedFile,
  setSelectedFile,
  fetchJuboData,
  setIsLoading,
  setUploadSuccess,
}) => {
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setModalIsOpen(false);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post(API.PDF, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      fetchJuboData();
      setUploadSuccess(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    setSelectedFile(null);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      contentLabel='File Upload'
      overlayClassName='modalOverlay'
      className='modalContent'
    >
      <h2 className='modalTitle'>주보 업로드</h2>
      <form onSubmit={handleFormSubmit} className='modalForm'>
        <input
          type='file'
          id='fileUpload'
          accept='application/pdf'
          onChange={handleFileChange}
        />
        {selectedFile ? (
          <div className='selectedFileWrapper'>{selectedFile.name}</div>
        ) : (
          <div className='emptySelect'></div>
        )}
        <label
          htmlFor='fileUpload'
          className='fileUploadLabel btn btn-secondary btn-sm'
        >
          <div className='fileUploadContent'>
            <BackupIcon />
            <span>파일 선택</span>
          </div>
        </label>
        <div className='buttonContainer'>
          <div>
            <button
              type='submit'
              className='btn btn-primary submitBtn'
              disabled={selectedFile == null}
            >
              Submit
            </button>
          </div>
          <button
            onClick={() => {
              setModalIsOpen(false);
              setSelectedFile(null);
            }}
            className='btn btn-outline-dark'
          >
            Close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UploadModal;
