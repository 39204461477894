import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/auth/AuthContext";

import onnuri_footer from "../../media/images/onnuri-footer-logo.png";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
} from "react-bootstrap";

const Login = () => {
  const navigate = useNavigate();
  const { login, error, clearError, isAuthenticated } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loginError = error !== null;

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        clearError();
      }, 3000);
    }
    return () => clearTimeout(timer); // Clear timeout if the component is unmounted
  }, [error, clearError]);

  // Redirect if authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/admin");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login({ username, password });
  };

  return (
    <div className="login-background">
      <Container>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col sm={6} md={4}>
            <Card>
              <Card.Body>
                <Card.Title className="text-center mb-4">
                  <img src={onnuri_footer} alt="onnuri logo"></img>
                </Card.Title>
                <Alert
                  className="loginFail"
                  variant="danger"
                  show={loginError}
                  transition
                >
                  Please check your username and password.
                </Alert>
                <Form
                  className="d-flex justify-content-center flex-column"
                  onSubmit={handleSubmit}
                >
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control
                      type="password"
                      placeholder="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    className="loginBtn"
                    variant="primary"
                    type="submit"
                    block
                  >
                    Log in
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
