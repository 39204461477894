import { LOGIN_SUCCESS, AUTH_ERROR, LOGIN_FAIL, CLEAR_ERROR } from "../types";

const App = (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      sessionStorage.setItem("token", action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        error: null,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      sessionStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default App;
