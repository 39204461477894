import React from 'react';
import { SidebarData } from './SidebarData';
import logo from '../../../media/images/side_logo.png';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className='sidebar'>
      <div className='logoContainer'>
        <img src={logo} alt='logo_side' className='logoSide' />
      </div>
      <ul className='sidebarList'>
        {SidebarData.map((val, key) => {
          return (
            <li key={key} className='sidebarRow'>
              <Link to={val.link} className='sidebarLink'>
                <div className='linkContent'>
                  <div id='icon'>{val.icon}</div>
                  <div id='title'>{val.title}</div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
