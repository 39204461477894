import React, { useContext } from 'react';
import AuthContext from '../../contexts/auth/AuthContext';
import { Navigate } from 'react-router-dom';
import Sidebar from './Common/Sidebar';

const Admin = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  // Redirect to login page if user is not authenticated
  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  }

  // Render the dashboard if user is authenticated
  return (
    <div className='adminPage'>
      <Sidebar />
      <main>{children}</main>
    </div>
  );
};

export default Admin;
