import { useReducer } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import authReducer from "./authReducer";
import { LOGIN_SUCCESS, LOGIN_FAIL, CLEAR_ERROR } from "../types";
import API from "../../config/api";

const AuthState = (props) => {
  const initialState = {
    isAuthenticated: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const clearError = () => {
    dispatch({ type: CLEAR_ERROR });
  };

  // Login User
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(API.LOGIN, formData, config);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: res.data,
        },
      });
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response ? err.response.data : "Server error",
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        error: state.error,
        login,
        clearError,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
